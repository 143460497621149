<template>
  <nav class="navbar-default" role="navigation">
    <div class="sidebar-collapse">
      <ul class="nav metismenu" id="side-menu">
        <template v-for="(acount, index) in acounts">
          <li
            :key="index"
            :class="{ active: $route.params.id === acount.msal_id }"
          >
            <router-link :to="`/mailbox/${acount.msal_id}/`">
              <i class="fa fa-pie-chart"></i>
              <span class="nav-label">{{ acount.email }}</span>
            </router-link>
          </li>
        </template>
      </ul>
    </div>
  </nav>
</template>

<script>
import MailService from "@/services/mail.service.js";
export default {
  name: "MailboxList",
  data() {
    return {
      acounts: {}
    };
  },
  created() {
    this.get();
  },
  methods: {
    get() {
      new MailService()
        .fetchAllEmails()
        .then(resp => {
          console.log(resp);
          this.acounts = resp.data.data;
          this.$emit("redy", true);
          console.log("mailbox_ready");
          // this.$router.push({
          //   path: `/mailbox/${this.acounts[0].msal_id}/`
          // });
        })
        .catch(err => {
          this.$toastr.error(err);
          console.log(err);
        });
    }
  }
};
</script>
