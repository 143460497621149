<template>
  <div class="row">
    <div class="col-lg-2 pl-0">
      <MailboxList @redy="test" />
    </div>
    <div class="col-lg-10 p-0 dupa">
      <template v-if="mailbox_redy">
        <router-view :key="$route.fullPath" />
      </template>
    </div>
  </div>
</template>

<script>
import MailboxList from "@/components/mailbox/MailboxList.vue";

export default {
  name: "MailboxWrapper",
  components: {
    MailboxList
  },
  data() {
    return {
      mailbox_redy: false
    };
  },
  methods: {
    test(val) {
      this.mailbox_redy = val;
      this.$forceUpdate();
    }
  }
};
</script>
